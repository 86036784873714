import { Container } from "react-bootstrap";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function KeyFeatures(): ReactElement {
  const { t } = useTranslation();
  const keyFeaturesData = [
    {
      imgSrc: "/assets/img/cashlessHospitalizationHealth.svg",
      title: t('solar_subheading_explosions'),
      description: t('solar_subheading_description_explosions')
    },
    {
      imgSrc: "/assets/img/temporaryDisability-pa.svg",
      title: t('solar_subheading_aircraft_damage'),
      description: t('solar_subheading_description__aircraft_damage')
    },
    {
      imgSrc: "/assets/img/medicalExamHealth.svg",
      title: t('solar_subheading_identification_documents_Covered'),
      description: t('solar_subheading_description_identification_documents_Covered')
    },
    {
      imgSrc: "/assets/img/accidentalMed-pa.svg",
      title: t('solar_subheading_home_contents'),
      description: t('solar_subheading_description_home_contents')
    }
  ];
  return (
    <div className="landing-page-wrapper mt-5">
      <section className="exclusion-section keyFeatures-pa my-health-takaful-bnr">
        <Container>
          <h1 className="mhtb-title text-center mb-5">
            {t("solar_heading_key")} <span className="text-primary"> {t("solar_heading_features")}</span>
          </h1>
          <div className="row">
            {keyFeaturesData.map((feature, index) => (
              <div
                className="col-md-6 mb-2 mb-md-4"
                key={index}
              >
                <div className="row pa-kf-box mx-0 align-items-center">
                  <div className="col-3 text-center">
                    <img
                      src={feature.imgSrc}
                      alt={feature.title}
                    />
                  </div>
                  <div className="col-9">
                    <h4 className="pa-kf-heading mb-1">{feature.title}</h4>
                    <p className="pa-kf-subheading mb-0">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
}
