import SolarHome from "../../modules/solar/container/home";
import SMEHome from "../../modules/sme/container/home";
import React, { lazy } from "react";

const Health = lazy(() => import("../../modules/health/container/home"));
const SehatHome = lazy(() => import("../../modules/sehat/container/home/heroBanner/index.tsx"));
const HealthPlans = lazy(() => import("../../modules/health/container/planListing"));

const HealthPlansDetail = lazy(() => import("../../modules/health/container/planDetails"));
const HealthPlansCompare = lazy(() => import("../../modules/health/container/compare"));

const HealthOrderDetailsDocumentUpload = lazy(() => import("../../modules/health/container/orderDetails/document-upload"));
const HealthOrderDetailsPaymentDetails = lazy(() => import("../../modules/health/container/orderDetails/payment-details"));
const HealthOrderDetailsPersonalDetails = lazy(() => import("../../modules/health/container/orderDetails/personal-details"));
const HealthOrderDetailsReviewDetails = lazy(() => import("../../modules/health/container/orderDetails/review-details"));

export const healthRoutes = [
  {
    path: "/health",
    element: <Health />
  },
  {
    path: "/sehatzamin",
    element: <SehatHome />
  },
  {
    path: "/sme",
    element: <SMEHome />
  },
  {
    path: "/solar",
    element: <SolarHome />
  },
  {
    path: "health/plans/:quotationID",
    element: <HealthPlans />
  },
  {
    path: "/health/plans/:quotationID/detail/:id",
    element: <HealthPlansDetail />
  },
  {
    path: "/health/plans/:quotationID/compare/:id",
    element: <HealthPlansCompare />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/document-upload",
    element: <HealthOrderDetailsDocumentUpload />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/payment-details",
    element: <HealthOrderDetailsPaymentDetails />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/personal-details",
    element: <HealthOrderDetailsPersonalDetails />
  },
  {
    path: "/health/order-details/:quotationID/:cartID/review-details",
    element: <HealthOrderDetailsReviewDetails />
  }
];
