import { Dropdown, Modal, Offcanvas, Collapse, Button } from "react-bootstrap";
import React, { ReactElement, useEffect, useState } from "react";
import {
  ArrowBottom,
  Auto,
  Travel,
  Family,
  Health,
  Headset,
  Cart,
  Hamburger,
  Support2,
  Email2,
  DashboardIcon,
  Logout
} from "../../../../shared/components/icons";
import Login from "../../../authentication/container/login";
import Signup from "../../../authentication/container/signup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ResetUserDetails, getAuthenticatedUser } from "../../../../redux/features/auth";
import CustomModal from "../../../../shared/components/customModal";
import CartBodyPersonalAccident from "../../../../shared/components/personalAccident/cart";
import CartBodyHouseHold from "../../../../shared/components/houseHold/cart";
import CartBodyLife from "../../../../shared/components/life/cart";
import CartBody from "../../../../shared/components/health/cart";
import CartFooterHouseHold from "../../../../shared/components/houseHold/cart/CartFooter";
import CartFooterLife from "../../../../shared/components/life/cart/CartFooter";
import CartFooterPersonalAccident from "../../../../shared/components/personalAccident/cart/CartFooter";
import CartFooter from "../../../../shared/components/health/cart/CartFooter";
import { RemovePlanCart, getCartBarStatus, getCartPlans, getCurrentPayable, updateCartBarStatus } from "../../../../redux/features/health/cart";
import {
  RemovePlanCart as RemovePlanCartPersonalAccident,
  getCartBarStatus as getCartBarStatusPersonalAccident,
  getCartPlans as getCartPlansPersonalAccident,
  getCurrentPayable as getCurrentPayablePersonalAccident,
  updateCartBarStatus as updateCartBarStatusPersonalAccident
} from "../../../../redux/features/personalAccident/cart";

import {
  RemovePlanCart as RemovePlanCartHouseHold,
  getCartBarStatus as getCartBarStatusHouseHold,
  getCartPlans as getCartPlansHouseHold,
  getCurrentPayable as getCurrentPayableHouseHold,
  updateCartBarStatus as updateCartBarStatusHouseHold
} from "../../../../redux/features/houseHold/cart";

import {
  RemovePlanCart as RemovePlanCarLife,
  getCartBarStatus as getCartBarStatusLife,
  getCartPlans as getCartPlansLife,
  getCurrentPayable as getCurrentPayableLife,
  updateCartBarStatus as updateCartBarStatusLife
} from "../../../../redux/features/life/cart";

import { appendQueryInPath, isUserCustomer, getUrlWithQueryParams, navigateToUrl, userRepresentableDetails } from "../../../../shared/helper";
import { PlanTypes, RateModeTypes, Routes } from "../../../../shared/constant";
import { getLeadDetails, getUserDetails } from "../../../../redux/features/health/userDetails";
import {
  getLeadDetails as getLeadDetailsPersonalAccident,
  getUserDetails as getUserDetailsPersonalAccident
} from "../../../../redux/features/personalAccident/userDetails";
import {
  getLeadDetails as getLeadDetailsHouseHold,
  getUserDetails as getUserDetailsHouseHold
} from "../../../../redux/features/houseHold/userDetails";
import { getLeadDetails as getLeadDetailsLife, getUserDetails as getUserDetailsLife } from "../../../../redux/features/life/userDetails";
import { removeCart } from "../../../../shared/services/health/cart";
import { removeCart as removeCartPersonalAccident } from "../../../../shared/services/personalAccident/cart";
import { removeCart as removeCartHouseHold } from "../../../../shared/services/houseHold/cart";
import { removeCart as removeCartLife } from "../../../../shared/services/life/cart";
import { logout } from "../../container/services";
import Cookies from "js-cookie";
import { AddCompare } from "../../../../redux/features/health/compare";
import { AddCompare as AddComparePersonalAccident } from "../../../../redux/features/personalAccident/compare";
import { AddCompare as AddCompareHouseHold } from "../../../../redux/features/houseHold/compare";
import { AddCompare as AddCompareLife } from "../../../../redux/features/life/compare";
import { IHealthPlanDetails } from "../../../../modules/health/utils/interfaces";
import { IPersonalAccidentPlanDetails } from "../../../../modules/personalAccident/utils/interfaces";
import { DefaultCoverageLimit } from "../../../../modules/health/utils/constants/planListing.constant";
import { DefaultCoverageLimit as DefaultCoverageLimitPersonalAccident } from "../../../../modules/personalAccident/utils/constants/planListing.constant";
import { DefaultCoverageLimit as DefaultCoverageLimitHouseHold } from "../../../../modules/houseHold/utils/constants/planListing.constant";
import { DefaultCoverageLimit as DefaultCoverageLimitLife } from "../../../../modules/life/utils/constants/planListing.constant";

import { updateCart } from "../../../../modules/health/helpers/cart";
import { updateCart as updateCartPersonalAccident } from "../../../../modules/personalAccident/helpers/cart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useQuery from "../../../../shared/hook/useQuery";
import { useUpdateCart } from "../../../../modules/health/hooks/cart/useCart";
import { useUpdateCart as useUpdateCartPersonalAccident } from "../../../../modules/personalAccident/hooks/cart/useCart";
import { useUpdateCart as useUpdateCartHouseHold } from "../../../../modules/houseHold/hooks/cart/useCart";
import { useUpdateCart as useUpdateCartLife } from "../../../../modules/life/hooks/cart/useCart";
import { checkIsPersonalAccident, checkIsHouseHold, checkIsLife } from "../../../../shared/regex";
import MegaDropdown from "../megaDropdown";

export default function HeaderComponent(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isPersonalAccident = checkIsPersonalAccident.test(pathname);
  const isHouseHold = checkIsHouseHold.test(pathname);
  const isLife = checkIsLife.test(pathname);

  const { quotationID } = useParams();
  const { params: query } = useQuery();
  const [isCartViewPlanInformed, setIsCartViewPlanInformed] = useState(false);
  const { quoteID: userQuoteID } = useSelector(
    isPersonalAccident ? getUserDetailsPersonalAccident : isHouseHold ? getUserDetailsHouseHold : isLife ? getUserDetailsLife : getUserDetails
  );
  const cartCurrentPayable = useSelector(
    isPersonalAccident
      ? getCurrentPayablePersonalAccident
      : isHouseHold
      ? getCurrentPayableHouseHold
      : isLife
      ? getCurrentPayableLife
      : getCurrentPayable
  );
  const details = useSelector(
    isPersonalAccident ? getLeadDetailsPersonalAccident : isHouseHold ? getLeadDetailsHouseHold : isLife ? getLeadDetailsLife : getLeadDetails
  );
  const excludedPaths = [
    "/health",
    "/personal-accident",
    "/house-hold",
    "/sehatzamin",
    "/sme"
  ];
  const [show, setShow] = useState<boolean>(false);
  const userDetails = useSelector(getAuthenticatedUser);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const isOrderDetails = pathname.includes("order-details");
  const isExcludedPath = excludedPaths.includes(pathname);
  const { authenticatedUser } = useSelector(getAuthenticatedUser) ?? {};
  const userType = authenticatedUser?.userType;
  const isCustomer = isUserCustomer(userType?.key);

  const onCloseModal = () => {
    setShow(!show);
    navbarClose();
  };
  isPersonalAccident
    ? useUpdateCartPersonalAccident({ quotationID: quotationID as string, asPath: pathname })
    : isHouseHold
    ? useUpdateCartHouseHold({ quotationID: quotationID as string, asPath: pathname })
    : isLife
    ? useUpdateCartLife({ quotationID: quotationID as string, asPath: pathname })
    : useUpdateCart({ quotationID: quotationID as string, asPath: pathname });

  const showCart = useSelector(
    isPersonalAccident ? getCartBarStatusPersonalAccident : isHouseHold ? getCartBarStatusHouseHold : isLife ? getCartBarStatusLife : getCartBarStatus
  );
  const cartModalHide = () => {
    isPersonalAccident
      ? dispatch(updateCartBarStatusPersonalAccident(false))
      : isHouseHold
      ? dispatch(updateCartBarStatusHouseHold(false))
      : isLife
      ? dispatch(updateCartBarStatusLife(false))
      : dispatch(updateCartBarStatus(false));
  };
  const cartModalShow = () => {
    isPersonalAccident
      ? dispatch(updateCartBarStatusPersonalAccident(true))
      : isHouseHold
      ? dispatch(updateCartBarStatusHouseHold(true))
      : isLife
      ? dispatch(updateCartBarStatusLife(true))
      : dispatch(updateCartBarStatus(true));
  };
  useEffect(() => {
    return () => {
      setIsLogin(true);
    };
  }, [show]);

  const [showNavbar, setShowNavbar] = useState(false);

  const navbarClose = () => setShowNavbar(false);
  const navbarShow = () => setShowNavbar(true);

  const [mobileNavProductOpen, setMobileNavProductOpen] = useState(false);
  const [mobileNavClaimOpen, setMobileNavClaimOpen] = useState(false);

  const cartPlans = useSelector(
    isPersonalAccident ? getCartPlansPersonalAccident : isHouseHold ? getCartPlansHouseHold : isLife ? getCartPlansLife : getCartPlans
  );
  const cartSelectedPlanTypes = Object.keys(cartPlans);
  const numberOfCartPlans = cartSelectedPlanTypes.length;
  const totalAllowedPlanInCart = 2;
  const shouldProceedToCheckout = isCartViewPlanInformed || numberOfCartPlans === totalAllowedPlanInCart;

  const isParentPlanType = cartSelectedPlanTypes[0] === PlanTypes.PARENT;
  const nonSelectedPlanType = userRepresentableDetails(details?.details, !isParentPlanType, t);

  const onClickViewPlan = () => {
    const quoteID = quotationID || userQuoteID;
    if (!quoteID) return;
    setIsCartViewPlanInformed(true);
    dispatch(
      isPersonalAccident ? AddComparePersonalAccident([]) : isHouseHold ? AddCompareHouseHold([]) : isLife ? AddCompareLife([]) : AddCompare([])
    );
    const getQueryParams = () => {
      const filterInitialValues = {
        planType: nonSelectedPlanType.planType,
        payMonthly: query.rateModeType === RateModeTypes.MONTHLY,
        coverageLimit: isPersonalAccident
          ? DefaultCoverageLimitPersonalAccident
          : isHouseHold
          ? DefaultCoverageLimitHouseHold
          : isLife
          ? DefaultCoverageLimitLife
          : DefaultCoverageLimit,
        coverageIds: [],
        serviceIds: [],
        takafulProviderIds: [],
        sortOrderFilter: "name_ASC"
      };
      return appendQueryInPath(filterInitialValues);
    };

    const queryParams = getQueryParams();
    navigate(getUrlWithQueryParams(`${Routes.LANDING_PAGE}/${Routes.HEALTH_PLAN_LISTING_PAGE}/${quoteID}`, queryParams), { replace: true });

    cartModalHide();
  };

  const onCheckout = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, { cartID }] = Object.entries(cartPlans)[0] as [string, IHealthPlanDetails | IPersonalAccidentPlanDetails];

    setIsCartViewPlanInformed(true);
    cartModalHide();
    isPersonalAccident
      ? // navigate(`/personal-accident/order-details/${quotationID}/${cartID}/document-upload`)
        navigate(`/personal-accident/order-details/${quotationID}/${cartID}/personal-details`, { replace: true, state: { submitted: true } })
      : isHouseHold
      ? navigate(`/house-hold/order-details/${quotationID}/${cartID}/personal-details`, { replace: true, state: { submitted: true } })
      : isLife
      ? navigate(`/life/order-details/${quotationID}/${cartID}/personal-details`, { replace: true, state: { submitted: true } })
      : navigate(`/health/order-details/${quotationID}/${cartID}/personal-details`);
  };

  const onCartRemove = async (planID: number, cartID: string, planType: string) => {
    const { data: cartData } = isPersonalAccident
      ? await removeCartPersonalAccident({ planIds: [planID], cartId: cartID })
      : isHouseHold
      ? await removeCartHouseHold({ planIds: [planID], cartId: cartID })
      : isLife
      ? await removeCartLife({ planIds: [planID], cartId: cartID })
      : await removeCart({ planIds: [planID], cartId: cartID });
    if (cartData?.success) {
      isPersonalAccident
        ? updateCartPersonalAccident({ quotationID: quotationID as string, isCustomer, dispatch, navigate })
        : updateCart({ quotationID: quotationID as string, isCustomer, dispatch, navigate });
      dispatch(
        isPersonalAccident
          ? RemovePlanCartPersonalAccident(planType)
          : isHouseHold
          ? RemovePlanCartHouseHold(planType)
          : isLife
          ? RemovePlanCarLife(planType)
          : RemovePlanCart(planType)
      );
    }
  };

  const checkRoute = () => {
    if (pathname.includes("customer")) {
      checkIsPersonalAccident.test(pathname)
        ? navigate("/personal-accident", { replace: true })
        : isHouseHold
        ? navigate("/house-hold", { replace: true })
        : isLife
        ? navigate("/life", { replace: true })
        : navigate("/health", { replace: true });
    }
  };

  const userLogout = async () => {
    const { data: res } = await logout();
    if (res.data.success) {
      dispatch(ResetUserDetails());
      Cookies.remove("authorization");
      navigate(`${pathname}${search}`, { replace: true });
      checkRoute();
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navItems = [
    {
      name: "Auto",
      namehref: "https://takafulbazaar.pk/auto",
      icon: "../../assets/img/autoNavIcon.svg",
      categories: ["Standard", "Third Party", "Zero Depreciation"],
      catLinks: [
        "https://takafulbazaar.pk/auto?id=5",
        "https://takafulbazaar.pk/auto?id=10",
        "https://takafulbazaar.pk/auto?id=8"
      ]
    },
    {
      name: "Health",
      namehref: "https://health.takafulbazaar.pk/health",
      icon: "../../assets/img/healthNavIcon.svg",
      categories: ["Personal Accident", "Group Health Takaful", "Sehat Zamin"],
      catLinks: [
        "https://health.takafulbazaar.pk/personal-accident",
        "https://health.takafulbazaar.pk/sme",
        "https://health.takafulbazaar.pk/sehatzamin"
      ]
    },
    {
      name: "Travel",
      namehref: "https://takafulbazaar.pk/travel",
      icon: "../../assets/img/travelNavIcon.svg",
      categories: ["Domestic", "Umrah/Hajj", "Schengen Countries", "Worldwide"],
      catLinks: [
        "https://takafulbazaar.pk/travel?id=0",
        "https://takafulbazaar.pk/travel?id=7",
        "https://takafulbazaar.pk/travel?id=5",
        "https://takafulbazaar.pk/travel?id=6"
      ]
    },
    {
      name: "Home",
      namehref: "https://health.takafulbazaar.pk/house-hold",
      icon: "../../assets/img/houseNavIcon.svg",
      categories: ["Standard"],
      catLinks: ["https://health.takafulbazaar.pk/house-hold"]
    },
    {
      name: "Solar",
      namehref: "https://health.takafulbazaar.pk/solar",
      icon: "../../assets/img/solarIcon.png",
      categories: ["Standard"],
      catLinks: ["https://health.takafulbazaar.pk/solar"]
    }
  ];
  

  return (
    <>
      <header className="main-header">
        <a
          className="mh-logo"
          href="https://takafulbazaar.pk"
        >
          <img
            className="mh-logo fill-image"
            src="/assets/img/logo-dark.svg"
            alt="Takaful Bazaar"
          />
        </a>
        <nav className="mh-nav">
          <ul className="mh-n-list">
            <li className="mh-n-item mh-responsive-hide">
              <a
                className="mh-n-link"
                onClick={() => navigateToUrl("/about-us")}
              >
                {t("header_&_footer_about_us")}
              </a>
            </li>

            <li className="mh-n-item mh-responsive-hide">
              <div className="mh-n-dropdown">
                <a
                  className="mh-n-link"
                  onClick={() => toggleDropdown()} // Toggle dropdown visibility
                >
                  {t("header_&_footer_products")}
                  <span
                    className={`mh-n-arrow ${isDropdownOpen ? "open" : "closed"}`} // Dynamically apply classes
                  >
                    <ArrowBottom />
                  </span>
                </a>

                {isDropdownOpen && ( // Conditionally render the dropdown menu
                  <div className="productsDropdown">
                    <MegaDropdown navItems={navItems} />
                  </div>
                )}
              </div>
            </li>

            {/* <li className="mh-n-item mh-responsive-hide">
              <Dropdown className="mh-n-dropdown">
                <Dropdown.Toggle
                  as="a"
                  className="mh-n-link"
                >
                  {t("header_&_footer_claims")}
                  <ArrowBottom className="mh-n-arrow" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigateToUrl("/auto/claims")}>
                    <div className="mh-n-icon mh-n-lg">
                      <Auto />
                    </div>
                    {`${t("customer_policy_auto_type_label")} ${t("header_&_footer_takaful")}`}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigateToUrl("/travel")}>
                    <div className="mh-n-icon">
                      <Travel />
                    </div>
                    {`${t("customer_policy_travel_type_label")} ${t("header_&_footer_takaful")}`}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/health")}>
                    <div className="mh-n-icon">
                      <Health />
                    </div>
                    {`${t("customer_policy_health_type_label")} ${t("header_&_footer_takaful")}`}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="mh-n-item mh-responsive-hide">
              <a
                className="mh-n-link"
                onClick={() => navigateToUrl("/faq")}
              >
                {t("header_&_footer_faqs")}
              </a>
            </li> */}
            {/* <li className="mh-n-item mh-responsive-hide">
              <a
                className="mh-n-link"
                onClick={() => navigateToUrl("/contact-us")}
              >
                <Headset className="mh-n-icon" />
                {t("header_&_footer_contact_us")}
              </a>
            </li> */}
            {!isOrderDetails && !isExcludedPath && (
              <li className="mh-n-item">
                <a
                  className="mh-n-link"
                  onClick={cartModalShow}
                >
                  <div className="mh-n-icon">
                    <Cart />
                    {!!numberOfCartPlans && <span className="mh-n-count">{numberOfCartPlans}</span>}
                  </div>
                  <span className="d-none d-lg-block">{t("header_&_footer_cart")}</span>
                </a>
              </li>
            )}
            {userDetails?.authenticatedUser?.id ? (
              <li className="mh-n-item mh-responsive-hide">
                <Dropdown className="mh-n-dropdown">
                  <Dropdown.Toggle
                    as="a"
                    className="mh-n-link"
                  >
                    <div className="mh-profile">
                      <figure className="mh-p-fig cursor-pointer">
                        <img
                          src={userDetails.authenticatedUser.profileImage || "/assets/img/profile-dummy.svg"}
                          alt="profile-image"
                        />
                      </figure>
                      <div className="mh-p-name cursor-pointer">{userDetails.authenticatedUser.name}</div>
                    </div>
                    <ArrowBottom className="mh-n-arrow text-primary" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/customer")}>
                      <div className="mh-n-icon">
                        <DashboardIcon />
                      </div>
                      {t("customer_dashboard_breadcrumb_label")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={userLogout}>
                      <div className="mh-n-icon sm">
                        <Logout />
                      </div>
                      {t("header_&_footer_logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            ) : (
              <li className="mh-n-item auth-btn mh-responsive-hide">
                <Button onClick={() => setShow(true)}>{`${t("auth_login_btn_text")} / ${t("header_&_footer_signup")}`}</Button>
              </li>
            )}

            <li className="mh-n-item mh-menu mh-responsive-show">
              <button
                type="button"
                className="mh-menu-btn"
                onClick={navbarShow}
              >
                <Hamburger />
              </button>
            </li>
          </ul>
        </nav>
      </header>
      {/* SIGNIN/SIGNUP MODAL */}
      <Modal
        dialogClassName="auth-modal"
        centered={true}
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isLogin ? (
              <div>
                {t("auth_login_heading")}
                <small>{t("auth_login_heading_paragraph")}</small>
              </div>
            ) : (
              <div>
                {t("auth_signup_heading")}
                <small>{t("auth_signup_heading_paragraph")}</small>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLogin && (
            <>
              <Login onCloseModal={onCloseModal} />
              <p className="m-0 mt-4 fs-14px text-center">
                {t("auth_signup_text_paragraph")}
                <a
                  className="text-primary c-pointer"
                  onClick={() => setIsLogin(!isLogin)}
                >
                  {t("auth_signup_text")}
                </a>
              </p>
            </>
          )}
          {!isLogin && (
            <>
              <Signup onCloseModal={onCloseModal} />
              <p className="m-0 mt-4 fs-14px text-center">
                {t("auth_login_text_paragraph")}
                <a
                  onClick={() => setIsLogin(!isLogin)}
                  className="text-primary c-pointer"
                >
                  {t("auth_login_text")}
                </a>
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>

      <CustomModal
        title={
          <div className="d-flex align-items-center text-">
            <Cart className="me-2" />
            {t("health_plan_cart_title")}
          </div>
        }
        modalClassName="drawer drawer-right"
        dialogClassName="cart-modal-wrapper"
        bodyClassName={"px-0"}
        size={"lg"}
        centered={false}
        show={showCart}
        onHide={cartModalHide}
        body={
          checkIsPersonalAccident && checkIsPersonalAccident?.test(pathname) ? (
            <CartBodyPersonalAccident
              onClose={cartModalHide}
              onCartRemove={onCartRemove}
            />
          ) : isHouseHold ? (
            <CartBodyHouseHold
              onClose={cartModalHide}
              onCartRemove={onCartRemove}
            />
          ) : isLife ? (
            <CartBodyLife
              onClose={cartModalHide}
              onCartRemove={onCartRemove}
            />
          ) : (
            <CartBody
              onClose={cartModalHide}
              onCartRemove={onCartRemove}
            />
          )
        }
        footer={
          !!numberOfCartPlans && checkIsPersonalAccident.test(pathname) ? (
            <CartFooterPersonalAccident
              remainingPlans={nonSelectedPlanType.value}
              shouldProceedToCheckout={shouldProceedToCheckout}
              cartPayable={cartCurrentPayable}
              onCheckout={onCheckout}
              onClickViewPlan={onClickViewPlan}
            />
          ) : isHouseHold ? (
            <CartFooterHouseHold
              remainingPlans={nonSelectedPlanType.value}
              shouldProceedToCheckout={shouldProceedToCheckout}
              cartPayable={cartCurrentPayable}
              onCheckout={onCheckout}
              onClickViewPlan={onClickViewPlan}
            />
          ) : isLife ? (
            <CartFooterLife
              remainingPlans={nonSelectedPlanType.value}
              shouldProceedToCheckout={shouldProceedToCheckout}
              cartPayable={cartCurrentPayable}
              onCheckout={onCheckout}
              onClickViewPlan={onClickViewPlan}
            />
          ) : (
            <CartFooter
              remainingPlans={nonSelectedPlanType.value}
              shouldProceedToCheckout={shouldProceedToCheckout}
              cartPayable={cartCurrentPayable}
              onCheckout={onCheckout}
              onClickViewPlan={onClickViewPlan}
            />
          )
        }
      />
      {/* MOBILE RESPONSIVE SIDEBAR */}
      <Offcanvas
        show={showNavbar}
        onHide={navbarClose}
        className="mobile-navbar"
      >
        <Offcanvas.Header className="bg-body-secondary py-4">
          {userDetails?.authenticatedUser?.id ? (
            <div className="w-100">
              <div className="mh-profile mh-profile-lg">
                <figure
                  className="mh-p-fig cursor-pointer"
                  onClick={() => navigate("/customer")}
                >
                  <img
                    src={userDetails.authenticatedUser.profileImage || "/assets/img/profile-dummy.svg"}
                    alt="profile-image"
                  />
                </figure>
                <div className="mh-p-content">
                  <div className="mh-p-name">{userDetails.authenticatedUser.name}</div>
                  <a
                    className="mh-p-logout"
                    onClick={userLogout}
                  >
                    {t("header_&_footer_logout")}
                  </a>
                </div>
              </div>
              <Button
                variant="outline-primary"
                size="lg"
                className="bg-white w-100 mt-4"
                onClick={() => navigate("/customer")}
              >
                {t("customer_dashboard_breadcrumb_label")}
              </Button>
            </div>
          ) : (
            <Button
              size="lg"
              onClick={() => setShow(true)}
            >
              {`${t("auth_login_btn_text")} / ${t("header_&_footer_signup")}`}
            </Button>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body className="py-0">
          <ul className="mn-nav">
            <li className="mn-n-item">
              <a
                className="mn-n-link"
                onClick={() => navigateToUrl("/about-us")}
              >
                {t("header_&_footer_about_us")}
              </a>
            </li>
            <li className="mn-n-item">
              <a
                className="mn-n-link"
                onClick={() => setMobileNavProductOpen(!mobileNavProductOpen)}
              >
                {t("header_&_footer_products")}

                <ArrowBottom className="mn-n-arrow" />
              </a>
              <Collapse in={mobileNavProductOpen}>
                <div className="mn-subnav">
                  <a
                    className="mn-s-link"
                    onClick={() => navigateToUrl("/auto")}
                  >
                    {`${t("customer_policy_auto_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a className="mn-s-link">{`${t("customer_policy_travel_type_label")} ${t("header_&_footer_takaful")}`}</a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/health")}
                  >
                    {`${t("customer_policy_health_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/personal-accident")}
                  >
                    {`${t("customer_policy_family_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/house-hold")}
                  >
                    {`${t("customer_policy_house_hold_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/life")}
                  >
                    {`${t("customer_policy_house_hold_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
              </Collapse>
            </li>
            <li className="mn-n-item">
              <a
                className="mn-n-link"
                onClick={() => setMobileNavClaimOpen(!mobileNavClaimOpen)}
              >
                {t("header_&_footer_claims")}
                <ArrowBottom className="mn-n-arrow" />
              </a>
              <Collapse in={mobileNavClaimOpen}>
                <div className="mn-subnav">
                  <a
                    className="mn-s-link"
                    onClick={() => navigateToUrl("/auto/claims")}
                  >
                    {`${t("customer_policy_auto_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a className="mn-s-link">{`${t("customer_policy_travel_type_label")} ${t("header_&_footer_takaful")}`}</a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/health")}
                  >
                    {`${t("customer_policy_health_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/personal-accident")}
                  >
                    {`${t("customer_policy_family_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/house-hold")}
                  >
                    {`${t("customer_policy_house_hold_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                  <a
                    className="mn-s-link"
                    onClick={() => navigate("/life")}
                  >
                    {`${t("customer_policy_house_hold_type_label")} ${t("header_&_footer_takaful")}`}
                  </a>
                </div>
              </Collapse>
            </li>
            <li className="mn-n-item">
              <a
                className="mn-n-link"
                onClick={() => navigateToUrl("/faq")}
              >
                {t("header_&_footer_faqs")}
              </a>
            </li>
            <li className="mn-n-item">
              <a
                className="mn-n-link"
                onClick={() => navigateToUrl("/contact-us")}
              >
                {t("header_&_footer_contact_us")}
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
        <div className="mn-foot">
          <button
            type="button"
            className="mn-contact mb-3"
          >
            <Email2 />
            <div
              onClick={e => {
                window.location.href = "mailto:support@takafulbazaar.pk?subject=General Enquiries";
                e.preventDefault();
              }}
            >
              <div className="fs-12px">{t("header_general_enquiries")}</div>
              <div className="fs-16px fw-medium">{t("header_support_takafulbazaar_pk")}</div>
            </div>
          </button>
          <button
            type="button"
            className="mn-contact"
          >
            <Support2 />
            <div>
              <div className="fs-12px">{t("header_contact_customer_service")}</div>
              <div className="fs-16px fw-medium">
                <span>{t("UAN_number")}</span>
              </div>
            </div>
          </button>
        </div>
      </Offcanvas>
    </>
  );
}
