import { Container } from "react-bootstrap";
import React, { ReactElement } from "react";
// eslint-disable-next-line import/extensions
import animationData from "../../../../../lotties/data-website.json";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import Platinum from "../platinumPackage/index.tsx";
import { Accordion } from "react-bootstrap";

export default function Faqs(): ReactElement {
  const { t } = useTranslation();

  const faqData = [
    {
      question: t("solar_faq_q1"),
      answer:
        t("solar_faq_a1")
    },
    {
      question: t("solar_faq_q2"),
      answer:
        t("solar_faq_a2")
    },
    {
      question: t("solar_faq_q3"),
      answer:
        t("solar_faq_a3")
    },
    {
      question: t("solar_faq_q4"),
      answer:
        t("solar_faq_a4")
    },
    {
      question: t("solar_faq_q5"),
      answer:
        t("solar_faq_a5")
    },
    {
      question: t("solar_faq_q6"),
      answer:
        t("solar_faq_a6")
    },
    {
      question: t("solar_faq_q7"),
      answer:
        t("solar_faq_a7")
    }
  
  ];

  return (
    <div className="landing-page-wrapper faq-pa">
      <section className="my-health-takaful-bnr pb-5">
        <Container className="text-center">
          <h1 className="mhtb-title">
            Frequently Asked <span className="text-primary">Questions</span>
          </h1>

          {/* Use mapping to dynamically create the accordion */}
          <Accordion
            defaultActiveKey="0"
            className="w-100 sehatZamin-accordion"
            id="basicAccordion"
          >
            {faqData.map((faq, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={index}
              >
                <Accordion.Header className="candb-title">{faq.question}</Accordion.Header>
                <Accordion.Body className="text-start">
                  <strong className="fw-normal candb-caption">{faq.answer}</strong>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Container>
      </section>
    </div>
  );
}
