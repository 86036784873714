"use Strict";
import React, { ReactElement, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Faqs from "../../component/home/faqs";
import Exclusions from "../../component/home/exclusions";
import KeyFeatures from "../../component/home/keyFeatures";
import WhyChooseUs from "../../component/home/whyChooseUs";
import Banner from "../../component/home/banner";

export default function SolarHome(): ReactElement {

  return (
    <div style={{overflowX:"hidden"}} className="landing-page-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Group Health Takaful | Takaful Bazaar</title>
        <link
          rel="canonical"
          href="https://health.takafulbazaar.pk/sme"
        />
        <meta
          name="description"
          content="SME"
        />
      </Helmet>
      <Banner />
      <WhyChooseUs />
      <KeyFeatures />
      <Exclusions />
      <Faqs />

    </div>
  );
}
