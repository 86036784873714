import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function WhyChooseUs(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="landing-page-wrapper">
      <section className="why-choose-us-section">
        <Container>
          <h1 className="wcus-title">
          {t("solar_what_does_solar_panel")}
            <span className="text-primary"> {t("solar_takaful_cover?")}</span>
            {/* {"?"} */}
          </h1>
          <div className="row gy-4">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="wcus-item">
                <img
                  src="/assets/img/solar-riotandstrike.png"
                  alt="cashless-repairs"
                />
                <h2 className="wcus-label">{t("solar_riot_&_strike_damage")}</h2>
                <p className="wcus-desc">{t("solar_riot_&_strike_damage_description")}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="wcus-item">
                <img
                  src="/assets/img/house-home-3.svg"
                  alt="better-rates"
                />
                <h2 className="wcus-label">{t("solar_burglary_&_housebreaking")}</h2>
                <p className="wcus-desc">{t("solar_burglary_&_housebreaking_description")}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="wcus-item">
                <img
                  src="/assets/img/solar-fire.png"
                  alt="assistance"
                />
                <h2 className="wcus-label">{t("solar_fire_&_lightening")}</h2>
                <p className="wcus-desc">{t("solar_fire_&_lightening_description")}</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
