import { Container } from "react-bootstrap";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function Exclusions(): ReactElement {
  const { t } = useTranslation();

  const coverageDetails = [
    {
      title: t("solar_exclusions_wear_tear"),
      captions: [t("solar_exclusions_wear_tear_data")]
    },
    {
      title: t("solar_exclusions_intentional_damage"),
      captions: [t("solar_exclusions_intentional_damage_data")]
    },
    {
      title: t("solar_exclusions_mysterious_disappearance"),
      captions: [t("solar_exclusions_mysterious_disappearance_data")]
    },
    {
      title: t("solar_exclusions_sabotage_and_terrorism"),
      captions: [t("solar_exclusions_sabotage_and_terrorism_data")]
    }
  ];

  return (
    <div className="landing-page-wrapper mt-5">
      <section className="exclusion-section exclusion-pa my-health-takaful-bnr">
        <Container>
          <h1 className="mhtb-title text-center">{t("solar_heading_exclusions")}</h1>
          <p className="mhtb-caption text-center">{t("solar_subheading_exclusions")}</p>

          <div className="sehatzamin-packages">
            {coverageDetails.map((detail, index) => (
              <div
                key={index}
                className={`col-md-12 d-flex justify-content-between ${
                  index !== coverageDetails.length - 1 ? "border-bottom-ruppees pt-4 pb-4" : "pt-4 pb-4"
                }`}
              >
                <div className="text-start">
                  <h4 className="candb-title crossmark-title">{detail.title}</h4>
                  {/* Map through captions array */}
                  {detail.captions.map((caption, captionIndex) => (
                    <p
                      key={captionIndex}
                      className="candb-caption ms-4 mb-0"
                    >
                      {caption}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
}
